<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-text-field
      v-model="form.album_cover"
      label="Album Cover URL"
      dense
      filled
      class="rounded-lg"
    />
    <v-text-field
      v-model="form.apple_music_url"
      label="Apple Music URL"
      dense
      filled
      class="rounded-lg"
    />
    <v-text-field
      v-model="form.spotify_url"
      :rules="MIXINS_REQUIRED_RULES"
      label="Spotify URL"
      dense
      filled
      class="rounded-lg"
    />
    <!-- <location-select-box
      v-model="form.location_id"
      :rules="MIXINS_REQUIRED_RULES"
      item-text="name"
      item-value="id"
      label="Location"
      dense
      filled
    ></location-select-box> -->
    <div>
      <span class="font-weight-bold text-color-title">
        When would you like to publish this?
      </span>
      <div class="mt-4">
        <v-date-picker
          v-model="form.date"
          full-width
          class="date-picker-no-header"
        ></v-date-picker>
      </div>
      <!-- <div>
        <v-menu
          ref="menu"
          v-model="timePicker"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value.sync="form.time"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="form.time"
              label="Select time"
              readonly
              v-bind="attrs"
              v-on="on"
              dense
              filled
              class="rounded-lg"
              :rules="MIXINS_REQUIRED_RULES"
            ></v-text-field>
          </template>
          <v-time-picker
            v-if="timePicker"
            v-model="form.time"
            full-width
            @click:minute="$refs.menu.save(form.time)"
          ></v-time-picker>
        </v-menu>
      </div> -->
    </div>
  </v-form>
</template>

<script>
export default {
  data: () => ({
    valid: false
    // timePicker: false
  }),
  props: {
    /**
     * Collection of data that will be supplied to this component
     */
    form: {
      type: Object,
      default: () => {
        return {};
      }
    },
    itemId: {
      type: Number,
      default: null
    }
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    }
  }
};
</script>
